import React from 'react';

function getWindowHeight() {
    return (
        (document &&
            document.documentElement &&
            document.documentElement.clientHeight) ||
        window.innerHeight
    );
}

export function useRealVerticalHeight() {
    const [height, setHeight] = React.useState(0);
    React.useLayoutEffect(() => {
        const updateHeight = () => setHeight(getWindowHeight());
        updateHeight();
        window.addEventListener("resize", updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        }
    }, []);
    return height;
}
import React from 'react';
import AutoCompleteSelect from "./AutoCompleteSelect";
import { getLanguagesOptionsById } from '../../utils';

export default function LanguageSelect({ value, label = 'Language', onChange, ...props  }) {
    const languageOptionsById = getLanguagesOptionsById();

    return (
        <AutoCompleteSelect
            options={languageOptionsById}
            value={value}
            label={label}
            onChange={onChange}
            {...props} />
    );
}
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Box, Button, TextField, Typography } from '@mui/material';
import ButtonWithProgress from "@nvapps/common/components/ui/ButtonWithProgress";
import StatusAlert from '@nvapps/common/components/ui/StatusAlert';
import React from 'react';
import { Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as usersApi from '../api/users';
import { getQueryParams, isEmailValid, urlEncode } from '../utils';
import FormPage from './FormPage';

export default function ForgotPassword () {
    const [username, setUsername] = React.useState("");
    const [error, setError] = React.useState(null);
    const [isSendingEmail, setIsSendingEmail] = React.useState(false);
    const isUsernameValid = isEmailValid(username);
    const navigate = useNavigate();
    const location = useLocation();
    const { returnPage = "/" } = getQueryParams(location);

    const handleSubmitClick = async (e) => {
        e.preventDefault();
        setIsSendingEmail(true);
        setError(null);
        try {
            await usersApi.forgotPassword({ username });
            const emailUrlEncoded = urlEncode(username);
            const returnPageEncoded = urlEncode(returnPage);
            navigate(`/password-recovery?returnPage=${returnPageEncoded}&username=${emailUrlEncoded}`);
        } catch (err) {
            setError(err.response.data);
        } finally {
            setIsSendingEmail(false);
        }
    }

    return (
        <FormPage>
            <Avatar sx={{ margin : 1, backgroundColor : 'secondary.light' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h2" variant="h5" fontWeight={400}>
                <Trans>forgotPassword</Trans>
            </Typography>
            <Box component="form" sx={{ width : '100%', mt : 1 }}>
                <TextField
                    label={<Trans>email</Trans>}
                    value={username}
                    variant="standard"
                    id="email"
                    autoComplete="email"
                    autoFocus
                    required
                    fullWidth
                    margin='normal'
                    onChange={e => setUsername(e.target.value)}
                    error={!isUsernameValid}
                    helperText={!isUsernameValid ? <Trans>invalidEmail</Trans> : ''} />

                <StatusAlert
                    style={{marginTop: "20px"}}
                    visible={error}
                    variant="error"
                    message={error && error.message}>
                </StatusAlert>

                <Box display="flex" justifyContent={"space-between"} mt={4}>
                    <Button
                        component={"a"}
                        href={returnPage}
                        variant="outlined"
                        color="primary">
                        <Trans>cancel</Trans>
                    </Button>
                    <ButtonWithProgress
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!isUsernameValid}
                        loading={isSendingEmail}
                        onClick={handleSubmitClick}>
                        <Trans>submit</Trans>
                    </ButtonWithProgress>
                </Box>
            </Box>
        </FormPage>
    );
}
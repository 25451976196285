import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { format as formatDate, formatRelative, formatDistance, isDate } from "date-fns";

import { locales } from '@nvapps/common/utils/dateUtils';

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'pt',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default,

            format: (value, format, lng) => {

                if (isDate(value)) {

                    const locale = locales[lng];

                    if (format === "short")
                        return formatDate(value, "P", { locale });
                    if (format === "long")
                        return formatDate(value, "PPPP", { locale });
                    if (format === "relative")
                        return formatRelative(value, new Date(), { locale });
                    if (format === "ago")
                        return formatDistance(value, new Date(), {
                            locale,
                            addSuffix: true
                        });

                    return formatDate(value, format, { locale });
                }

                return value;
            }
        },
        resources: {
            en: {
                translation: {
                    search: 'Search',
                    selected: '{{count}} selected'
                }
            },
            pt: {
                translation: {
                    register : 'Registar',
                    email : "Email",
                    invalidEmail : 'Email inválido',
                    password : 'Senha',
                    passwordMustBeAtLeast6Chars : 'A senha deve ter pelo menos 6 caracteres',
                    passwordConfirmation : 'Confirmação de senha',
                    incorretConfirmation : 'Confirmação incorreta',
                    name : 'Nome',
                    nameRequired : 'Nome obrigatório',
                    country : 'País',
                    language : 'Idioma',
                    allowNewsletter : 'Permitir o envio de newsletters informativas',
                    cancel : 'Cancelar',
                    submit : 'Submeter',
                    forgotPassword : 'Esqueci-me da senha',
                    passwordRecovery : 'Recuperação de senha',
                    recoveryCode : 'Código de recuperação',
                    invalidRecoveryCode : 'Código de recuperação inválido',
                    newPassword : 'Senha nova',
                    accountDeletion : 'Exclusão de conta',
                    code : 'Código',
                    invalidCode : 'Código inválido',
                    confirm : 'Confirmar',
                    cantDeleteAccount : 'Não foi possivel apagar a conta',
                    thankYou : 'Obrigado'
                }
            }
        }
    });


export default i18n;
import { blue } from '@mui/material/colors';
import { ptPT } from '@mui/material/locale';
import ThemeConfig from '@nvapps/common/theme';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ChangePassword from './pages/ChangePassword';
import ConfirmDelete from './pages/ConfirmDelete';
import ForgotPassword from './pages/ForgotPassword';
import PasswordRecovery from './pages/PasswordRecovery';
import Register from './pages/Register';
import UpdateProfile from './pages/UpdateProfile';
import { getQueryParams } from './utils';

function getPrimaryThemeColor(color) {

    const { mainThemeColor } = getQueryParams(window.location);
    return { main: color || mainThemeColor || blue['500'] };
}

function Redirect()
{
    console.log('empty')
    window.location.href = 'https://www.novatronica.com';
    return '';
}

function App() {

    const [ready, setReady] = useState(false);
    const [theme, setTheme] = useState(getPrimaryThemeColor());

    useEffect(() => {
        const handleMessage = (ev) => {
            if (ev.data.eventId === "setTheme") {
                setTheme(getPrimaryThemeColor(ev.data.payload));
            }
        };

        if (!ready) {
            window.parent.postMessage({ source: "changePassword.users.nvapps", eventId: "ready" }, "*");
            setReady(true);
        }

        window.addEventListener("message", handleMessage, false);

        return () => window.removeEventListener("message", handleMessage);
    }, []);

    return (
        <ThemeConfig type="light" language={ptPT} primary={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/password-recovery" element={<PasswordRecovery />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/update-profile" element={<UpdateProfile />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/confirm-delete-account" element={<ConfirmDelete />} />
                    <Route path='*' element={<Redirect />} />
                </Routes>
            </BrowserRouter>
        </ThemeConfig>
    );
}

export default App;

import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useRealVerticalHeight } from "../hooks";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";

const mainContentStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    position: "relative"
};

export function FullScreenPage({ children, title, actionButton, closeButtonProps }) {
    const realHeight = useRealVerticalHeight();
    return (
        <div style={{ display: "flex", flexDirection: "column", height: `${realHeight}px` }}>
            <AppBar position="sticky">
                <Toolbar style={{ display: "flex" }}>
                    <IconButton edge="start" color="inherit" aria-label="close" children={<CloseIcon />} {...closeButtonProps} />
                    <Typography variant="h6" style={{ flex: 1, marginLeft: "10px" }}>
                        {title}
                    </Typography>
                    {actionButton}
                </Toolbar>
            </AppBar>
            <main style={mainContentStyle}>
                {children}
            </main>
        </div>
    );
}

import { CssBaseline } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { useMemo } from 'react';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import GetPalette from './palette';
import GetShadows, { GetCustomShadows } from './shadows';
import shape from './shape';
import typography from './typography';

import { ptPT as corePtPT } from '@mui/material/locale';
import { ptBR as gridPtPT } from '@mui/x-data-grid'

export default function ThemeConfig({ type = "dark", children, primary }) {

    const palette = GetPalette(primary);
    const shadows = GetShadows(palette);
    const customShadows = GetCustomShadows(palette);

    const themeOptions = useMemo(
        () => ({
            type,
            palette,
            shape,
            typography,
            shadows,
            customShadows
        }),
        []
    );

    const theme = createTheme(themeOptions, corePtPT, gridPtPT);
    theme.components = { ...theme.components, ...componentsOverride(theme) };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
import { Box, TextField } from "@mui/material";
import ButtonWithProgress from "@nvapps/common/components/ui/ButtonWithProgress";
import StatusAlert from "@nvapps/common/components/ui/StatusAlert";
import React from "react";
import { useLocation } from "react-router-dom";
import { changePassword } from "../api/users";
import { getQueryParams } from "../utils";
import { FullScreenPage } from "./FullScreenPage";

export default function ChangePassword() {
    const [password, setPassword] = React.useState("");
    const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [error, setError] = React.useState(null);
    const location = useLocation();
    const { token = null } = getQueryParams(location);

    const isPasswordValid = password.length >= 6;
    const isConfirmacaoValid = passwordConfirmation === password;
    const isValid = isPasswordValid && isConfirmacaoValid;

    const handleClose = () => {
        window.parent.postMessage({ source: "changePassword.users.nvapps", eventId: "close" }, "*");
    }

    const handleChangePassword = async (e) =>  {
        e.preventDefault();
        setIsUpdating(true);
        setError(null);
        try {
            await changePassword({ newPassword: password }, token);
            handleClose();
        } catch (err) {
            setError(err.response.data);
        } finally {
            setIsUpdating(false);
        }
    }

    if (!token) return false;

    return (
        <FullScreenPage
            title="Change Password"
            closeButtonProps={{onClick: handleClose}}
            actionButton={
                <ButtonWithProgress
                    type="submit"
                    edge="end"
                    color="inherit"
                    disabled={!isValid}
                    loading={isUpdating}
                    onClick={handleChangePassword}
                    progressProps={{ sx: { color : "#fff !important" }}}>
                    Save
                </ButtonWithProgress>}>
            <Box p={2}>
                <TextField
                    label="New Password"
                    variant="standard"
                    value={password}
                    type="password"
                    name="password"
                    id="password"
                    required
                    fullWidth
                    margin='normal'
                    onChange={e => setPassword(e.target.value)}
                    error={!isPasswordValid}
                    helperText={!isPasswordValid ? 'The password should have at least 6 characters' : ''} />
               <TextField
                    label="Confirm the password"
                    variant="standard"
                    value={passwordConfirmation}
                    type="password"
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                    required
                    fullWidth
                    margin='normal'
                    onChange={e => setPasswordConfirmation(e.target.value)}
                    error={!isConfirmacaoValid}
                    helperText={!isConfirmacaoValid ? 'Incorrect confirmation' : ''} />

                <StatusAlert
                    sx={{ mt: 3 }}
                   visible={Boolean(error)}
                    variant="error"
                    message={error && error.message}>
                </StatusAlert>
            </Box>
        </FullScreenPage>
    );
}
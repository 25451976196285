import { lighten } from '@mui/material/styles';


export default function Switch(theme) {

    return {
        MuiSwitch: {
            styleOverrides: {
                root: {
                    '& .MuiSwitch-switchBase': {
                        color: lighten(theme.palette.primary.main, 0.8),

                        '&.Mui-checked': {
                        color: theme.palette.primary.main
                        }
                    }
                }
            }
        }
    };
}

import React from 'react';
import { getCountryOptionsById } from '../../utils';
import AutoCompleteSelect from './AutoCompleteSelect';

export default function CountrySelect({ value, label = 'Country', language = 'PT', onChange, ...props }) {
    const countryOptionsById = getCountryOptionsById(language);

    return (
        <AutoCompleteSelect
            options={countryOptionsById}
            value={value}
            label={label}
            onChange={onChange}
            {...props} />
    );
}
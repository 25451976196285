import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Box, Button, TextField, Typography } from '@mui/material';
import ButtonWithProgress from "@nvapps/common/components/ui/ButtonWithProgress";
import StatusAlert from '@nvapps/common/components/ui/StatusAlert';
import React from 'react';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as usersApi from '../api/users';
import { getQueryParams } from '../utils';
import FormPage from './FormPage';

export default function PasswordRecovery() {
    const [recoveryCode, setRecoveryCode] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
    const [isRecovering, setIsRecovering] = React.useState(false);
    const [error, setError] = React.useState(null);
    const location = useLocation();
    const { username, returnPage = "/" } = getQueryParams(location);

    const isCodigoRecuperacaoValid = recoveryCode.length === 8;
    const isPasswordValid = password.length >= 6;
    const isConfirmacaoValid = passwordConfirmation === password;
    const isValid = isCodigoRecuperacaoValid && isPasswordValid && isConfirmacaoValid;

    const handleSubmitClick = async (e) => {
        e.preventDefault();
        setIsRecovering(true);
        setError(null);
        try {
            await usersApi.passwordRecovery({ username, recoveryCode, password });
            window.location = returnPage;
        } catch (err) {
            setError(err.response.data);
        } finally {
            setIsRecovering(false);
        }
    }

    return (
        <FormPage>
            <Avatar sx={{ margin: 1, backgroundColor: 'secondary.light' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h2" variant="h5" fontWeight={400}>
                <Trans>passwordRecovery</Trans>
            </Typography>
            <Box sx={{ width: "100%" }}>
                <Typography component="h3" variant="subtitle1" align="center"
                    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                    {username}
                </Typography>
            </Box>
            <Box component="form" sx={{ width: '100%', mt: 1 }}>
                <TextField
                    label={<Trans>recoveryCode</Trans>}
                    value={recoveryCode}
                    variant="standard"
                    type="password"
                    name="recoveryCode"
                    id="recoveryCode"
                    autoFocus
                    required
                    fullWidth
                    margin='normal'
                    onChange={e => setRecoveryCode(e.target.value)}
                    error={!isCodigoRecuperacaoValid}
                    helperText={!isCodigoRecuperacaoValid ? <Trans>invalidRecoveryCode</Trans> : ''} />

                <TextField
                    label={<Trans>newPassword</Trans>}
                    variant="standard"
                    value={password}
                    type="password"
                    name="password"
                    id="password"
                    required
                    fullWidth
                    margin='normal'
                    onChange={e => setPassword(e.target.value)}
                    error={!isPasswordValid}
                    helperText={!isPasswordValid ? <Trans>passwordMustBeAtLeast6Chars</Trans> : ''} />

                <TextField
                    label={<Trans>passwordConfirmation</Trans>}
                    variant="standard"
                    value={passwordConfirmation}
                    type="password"
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                    required
                    fullWidth
                    margin='normal'
                    onChange={e => setPasswordConfirmation(e.target.value)}
                    error={!isConfirmacaoValid}
                    helperText={!isConfirmacaoValid ? <Trans>incorretConfirmation</Trans> : ''} />

                <StatusAlert
                    sx={{ mt: 3 }}
                    visible={Boolean(error)}
                    variant="error"
                    message={error && error.message}>
                </StatusAlert>

                <Box display="flex" justifyContent={"space-between"} mt={4}>
                    <Button
                        component={"a"}
                        href={returnPage}
                        color="primary">
                        <Trans>cancel</Trans>
                    </Button>
                    <ButtonWithProgress
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!isValid}
                        loading={isRecovering}
                        onClick={handleSubmitClick}>
                        <Trans>sumbit</Trans>
                    </ButtonWithProgress>
                </Box>
            </Box>
        </FormPage>
    );
}
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Box,  TextField, Typography } from '@mui/material';
import ButtonWithProgress from "@nvapps/common/components/ui/ButtonWithProgress";
import StatusAlert from '@nvapps/common/components/ui/StatusAlert';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as usersApi from '../api/users';
import { getQueryParams } from '../utils';
import FormPage from './FormPage';
import { Icon } from '@iconify/react';

export default function ConfirmDelete() {
    const [code, setCode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [removed, setRemoved] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation();
    const { id, username } = getQueryParams(location);

    const isCodeValid = code.length === 8;

    const handleSubmitClick = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        try {
            await usersApi.confirmDeleteAccount({ id, code });
            setRemoved(true);
        } catch (err) {
            setError(err.response.data);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <FormPage>
            <Avatar sx={{ margin: 1, backgroundColor: 'secondary.light' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h2" variant="h5" fontWeight={400}>
                <Trans>accountDeletion</Trans>
            </Typography>
            <Box sx={{ width: "100%" }}>
                <Typography component="h3" variant="subtitle1" align="center"
                    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                    {username}
                </Typography>
            </Box>
            {removed ? <Box sx={{ width: '100%', mt: 1, textAlign : 'center' }}>
                <Icon icon="ant-design:check-circle-outlined" height={75} color="green" />
                <Typography variant="h3" color="green" >
                    <Trans>thankYou</Trans>
                </Typography>
            </Box> : <Box component="form" sx={{ width: '100%', mt: 1 }}>
                <TextField
                    label={<Trans>code</Trans>}
                    value={code}
                    variant="standard"
                    type="password"
                    name="deleteCode"
                    id="deleteCode"
                    autoFocus
                    required
                    fullWidth
                    margin='normal'
                    onChange={e => setCode(e.target.value)}
                    error={!isCodeValid}
                    helperText={!isCodeValid ? <Trans>invalidCode</Trans> : ''} />

                <StatusAlert
                    sx={{ mt: 3 }}
                    visible={Boolean(error)}
                    variant="error"
                    message={(error && error.message) || <Trans>cantDeleteAccount</Trans>}>
                </StatusAlert>

                <Box display="flex" justifyContent={"center"} mt={4}>
                    <ButtonWithProgress
                        type="submit"
                        variant="contained"
                        color="error"
                        size="large"
                        disabled={!isCodeValid}
                        loading={isLoading}
                        onClick={handleSubmitClick}>
                        <Trans>confirm</Trans>
                    </ButtonWithProgress>
                </Box>
            </Box>}
        </FormPage>
    );
}
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const LogoImage = styled('img')(
    ({ theme }) => ({
        maxWidth:"350px",
        //filter: "brightness(0) invert(1) drop-shadow(0px 1px 2px rgba(0,0,0,.25))",
        "@media (max-width: 400px)": {
            maxWidth: "100%"
        }
    })
);

export default function Logo() {
    return (
        <Box sx={{ color : '#fff', pb : 2, display : 'flex', justifyContent : 'center' }}>
            <LogoImage src="logo-white.png"/>
        </Box>
    );
}

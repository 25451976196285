import React, { useState, useEffect } from 'react';
import { Alert, Snackbar } from '@mui/material';

export default function StatusAlert({ variant, visible, message, duration = 6000, onClose, ...other }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  const handleClose = (event) => {
    setOpen(false);
    console.log('close alert')
    onClose && onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={handleClose}>
      <Alert variant="filled" severity={variant} onClose={handleClose} {...other}>
        {message}
      </Alert>
    </Snackbar>
  );
}
import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

export default function AutoCompleteSelect({ value, options, label, onChange, textFieldProps }) {
    const option = options[value];

    return (
        <Autocomplete
            id="country-select-demo"
            options={Object.values(options)}
            autoHighlight
            value={option}
            getOptionLabel={option => option.label}
            onChange={onChange}
            renderInput={params => (
                <TextField
                    {...params}
                    {...textFieldProps}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        ...(textFieldProps && textFieldProps.inputProps),
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }} />
            )} />
    );
}

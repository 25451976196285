import axios from 'axios';

import countriesEN from './countries.json';
import countriesPT from './countries.pt.json';
import countriesFR from './countries.fr.json';
import countriesES from './countries.es.json';

import languages from './languages.json';

const tokenCookieName = "jwttoken";

const currencies = {
    "eur": "€",
    "aoa": "Kz"
}

export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};

export function isValidJwtToken(tokenData) {
    if (!tokenData || !tokenData.exp) return false;
    const expriation = (tokenData.exp - 120) * 1000; // exp-120seg
    return expriation > new Date();
}

export function setJwtTokenCookie(token) {
    document.cookie = `${tokenCookieName}=${token};path=/`;
}

export function getJwtTokenCookie() {
    const cookies = Object.fromEntries(
        document.cookie.split(/\s*;\s*/).map(e => e.split(/\s*=\s*/))
    );
    return cookies[tokenCookieName];
}

export function deleteJwtTokenCookie() {
    document.cookie = `${tokenCookieName}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export function isEmailValid(email) {
    return /^[\w-]+(?:\.[\w-]+)*@[\w-]+(?:\.[\w-]+)*\.\w+$/.test(email);
}

export function urlDecode(value) {
    return decodeURIComponent(value.replace(/\+/g, " "));
}

export function urlEncode(value) {
    return encodeURIComponent(value);
}

export function getQueryParams(location) {
    if (location.search.length === 0) return {};
    return Object.fromEntries(
        location.search.substring(1).split("&").map(i => {
            const [key, encodedValue] = i.split("=");
            const value = urlDecode(encodedValue);
            return [key, value];
        })
    );
}

export function getCurrencySymbol(name) {
    return currencies[name];
}

let languageOptionsById = null;
let countryOptionsById = null;
const languagesPermitted = ['pt', 'es', 'fr', 'en'];


function fillLanguageOptions() {
    languageOptionsById = {};
    for (const l of Object.keys(languages).filter(e=> languagesPermitted.includes(e))) {
        const lang = { value: l, label: languages[l] };
        languageOptionsById[l] = lang;
    }
}

const countriesLanguage =
{
    PT : countriesPT,
    ES : countriesES,
    EN : countriesEN,
    FR : countriesFR
}

function fillCountryOptions(language = 'PT') {
    countryOptionsById = {};
    const lang = countriesLanguage[(language || '').toUpperCase()] || countriesLanguage.PT;
    for (const c of Object.keys(lang)) {

        const label = lang[c];
        const country = { value: c, label: Array.isArray(label) ? label[1] : label  };
        countryOptionsById[c] = country;
    }
}

export function getCountryOptionsById(language = 'PT') {
    if (!countryOptionsById) fillCountryOptions(language);
    return countryOptionsById;
}

export function getLanguagesOptionsById() {
    if (!languageOptionsById) fillLanguageOptions();
    return languageOptionsById;
}

export function getInitials(name = '') {
    return name.replace(/\s+/, ' ')
        .split(' ')
        .slice(0, 2)
        .map((v) => v && v[0].toUpperCase())
        .join('');
}

export function formatString(a) {
    for (k in arguments) {
        a = a.replace("{" + k + "}", arguments[k])
    }
    return a
}

export function groupDataBy(data, idProp) {
    return data.reduce((r, a) => {
        r[a[idProp]] = [...r[a[idProp]] || [], a];
        return r;
    }, {});
}

export function indexDataBy(data, idProp) {
    return Object.fromEntries(data.map(d => [d[idProp], d]));
}

export function downloadUrlFile(fileName, url, token) {

    let options = {};
    if (token)
    {
        options.headers = { Authorization: `Bearer ${token}`};
    }

    return axios.get(url, {
        responseType: 'arraybuffer',
        ...options,
    }).then(res =>
    {
        downloadBlob(new File([res.data], fileName), fileName);
    })
}


export function downloadBlob(blob, fileName) {
    let link = document.createElement('a');
    link.setAttribute('href', window.URL.createObjectURL(blob));
    link.setAttribute('download', fileName);
    link.click();
};

export function validatePassword(password) {
    const regexPass = /^[a-zA-Z0-9]{6,}$/;
    return regexPass.test(password);
}

export const isImageFile = img => {
    const [, fileExt] = /\.([^.]*)$/.exec(img) || [];
    const validExtensions = new Set(["png", "jpg", "jpeg", "gif", "bmp"]);
    return validExtensions.has(fileExt);
}

export function isEmptyField(value)
{
    return value == undefined || value == null || (value || '').trim().length == 0;
}

export function getAuthenticatedImage(src, token)
{
    return axios.get(src,
    {
        headers: { Authorization: `Bearer ${token}`},
        responseType :"arraybuffer"
    })
    .then(res =>
    {
        return `data:${res.headers['content-type']};base64,${new Buffer(res.data).toString('base64')}`;
    })
    .catch(res => null);
}
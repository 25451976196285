import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import ButtonWithProgress from "@nvapps/common/components/ui/ButtonWithProgress";
import CountrySelect from '@nvapps/common/components/ui/CountrySelect';
import LanguageSelect from '@nvapps/common/components/ui/LanguageSelect';
import StatusAlert from '@nvapps/common/components/ui/StatusAlert';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as usersApi from '../api/users';
import { getQueryParams, isEmailValid } from '../utils';
import FormPage from './FormPage';

export default function Register() {
    const location = useLocation();
    const { returnPage = "/", appToken = null } = getQueryParams(location);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [name, setName] = useState("");
    const [country, setCountry] = useState("PT");
    const [language, setLanguage] = useState("pt");
    const [newsletter, setNewsletter] = useState(true);
    const [isRegistering, setIsRegistering] = useState(false);
    const [error, setError] = useState(false);
    const isUsernameValid = isEmailValid(username);
    const isPasswordValid = password.length >= 6;
    const isPasswordConfirmationValid = password === passwordConfirmation;
    const isNameValid = name.length > 0;
    const isValid = isUsernameValid && isPasswordValid && isPasswordConfirmationValid && isNameValid;

    const handleRegister = async (e) => {
        e.preventDefault();
        setIsRegistering(true);
        setError(null);
        try {
            await usersApi.register({ username, password, name, country, language, newsletter, appToken });
            window.location = returnPage;
        } catch (err) {
            setError(err.response.data);
        } finally {
            setIsRegistering(false);
        }
    }

    const margin = 'dense';

    return (
        <FormPage>
            <Avatar sx={{ margin: 1, backgroundColor: 'secondary.light' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h2" variant="h5" fontWeight={400}>
                <Trans>register</Trans>
            </Typography>
            <Box component="form" sx={{ width: '100%', mt: 1 }}>
                <TextField
                    label={<Trans>email</Trans>}
                    name="email"
                    id="email"
                    variant='standard'
                    value={username}
                    autoFocus
                    onChange={e => setUsername(e.target.value)}
                    fullWidth
                    required
                    margin={margin}
                    error={!isUsernameValid}
                    helperText={!isUsernameValid ? <Trans>invalidEmail</Trans> : ''} />

                <TextField
                    label={<Trans>password</Trans>}
                    name="password"
                    id="password"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    variant='standard'
                    fullWidth
                    required
                    margin={margin}
                    error={!isPasswordValid}
                    helperText={!isPasswordValid ? <Trans>passwordMustBeAtLeast6Chars</Trans> : ''} />

                <TextField
                    label={<Trans>passwordConfirmation</Trans>}
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                    type="password"
                    value={passwordConfirmation}
                    onChange={e => setPasswordConfirmation(e.target.value)}
                    variant='standard'
                    fullWidth
                    required
                    margin={margin}
                    error={!isPasswordConfirmationValid}
                    helperText={!isPasswordConfirmationValid ? <Trans>incorretConfirmation</Trans> : ''} />

                <TextField
                    label={<Trans>name</Trans>}
                    name="name"
                    id="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    required
                    margin={margin}
                    variant='standard'
                    error={!isNameValid}
                    helperText={!isNameValid ? <Trans>nameRequired</Trans> : ''} />

                <CountrySelect
                    value={country}
                    language="pt"
                    label={<Trans>country</Trans>}
                    onChange={(ev, opt) => opt && setCountry(opt.value)}
                    textFieldProps={{ variant: "standard", margin }} />

                <LanguageSelect
                    value={language}
                    label={<Trans>language</Trans>}
                    language
                    onChange={(ev, opt) => opt && setLanguage(opt.value)}
                    textFieldProps={{ variant: "standard", margin }} />

                <FormControlLabel
                    sx={{ mt : 2 }}
                    control={
                        <Checkbox
                            checked={newsletter}
                            onChange={e => setNewsletter(e.target.checked)} />
                    }
                    label={<Trans>allowNewsletter</Trans>} />

                <StatusAlert
                    sx={{ mt : 3 }}
                    visible={Boolean(error)}
                    variant="error"
                    message={error && error.message}>
                </StatusAlert>

                <Box display="flex" justifyContent={"space-between"} mt={4}>
                    <Button
                        component={"a"}
                        href={returnPage}
                        color="primary">
                        <Trans>cancel</Trans>
                    </Button>
                    <ButtonWithProgress
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!isValid}
                        loading={isRegistering}
                        onClick={handleRegister}>
                        <Trans>register</Trans>
                    </ButtonWithProgress>
                </Box>
            </Box>
        </FormPage>
    );
}
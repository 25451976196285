import React from 'react';
import green from '@mui/material/colors/green';
import { Button, CircularProgress } from '@mui/material';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

function ButtonWithProgress({ children, classes, disabled = false, loading = false, wrapperProps = {}, progressProps = {}, ...props }){

    return (
        <div className={classes.wrapper} {...wrapperProps}>
        <Button
            disabled={disabled || loading}
            {...props}>
            {children}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} {...progressProps} />}
      </div>
    )
}

export default withStyles(styles)(ButtonWithProgress);
import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import ButtonWithProgress from "@nvapps/common/components/ui/ButtonWithProgress";
import CountrySelect from '@nvapps/common/components/ui/CountrySelect';
import LanguageSelect from '@nvapps/common/components/ui/LanguageSelect';
import StatusAlert from '@nvapps/common/components/ui/StatusAlert';
import React from "react";
import { useLocation } from "react-router-dom";
import { getProfile, updateProfile } from "../api/users";
import { getQueryParams } from "../utils";
import { FullScreenPage } from "./FullScreenPage";

export default function UpdateProfile() {
    const [profile, setProfile] = React.useState(null);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [error, setError] = React.useState(null);
    const location = useLocation();
    const { token = null } = getQueryParams(location);

    const setField = (name, value) => {
        setProfile({
            ...profile,
            [name]: value
        });
    }

    React.useEffect(() => {
        if (!token) return;
        getProfile(token).then(res => {
            if (res.status === 200) setProfile(res.data);
        });
    }, [token]);

    const handleClose = () => {
        window.parent.postMessage({ source: "updateProfile.users.nvapps", eventId: "close" }, "*");
    }

    const handleUpdateProfile = async (e) => {
        e.preventDefault();
        setIsUpdating(true);
        setError(null);
        try {
            await updateProfile(profile, token);
            handleClose();
        } catch (err) {
            setError(err.response.data);
        } finally {
            setIsUpdating(false);
        }
    }

    if (!token || !profile) return false;

    const isNameValid = profile.name.length > 0;
    const isValid = isNameValid;

    return (
        <FullScreenPage
            title="Edit Profile"
            closeButtonProps={{ onClick: handleClose }}
            actionButton={<ButtonWithProgress
                type="submit"
                edge="end"
                color="inherit"
                variant="outlined"
                disabled={!isValid}
                loading={isUpdating}
                onClick={handleUpdateProfile}
                progressProps={{ sx: { color : "#fff !important" }}}>
                Save
            </ButtonWithProgress>}>
            <Box p={2} >
                <TextField
                    label="Name"
                    name="name"
                    id="name"
                    value={profile.name}
                    variant='standard'
                    onChange={e => setField("name", e.target.value)}
                    required
                    fullWidth
                    margin='normal'
                    error={!isNameValid}
                    helperText={!isNameValid ? 'Name required' : ''} />

                <CountrySelect
                    value={profile.country}
                    label={'Country'}
                    onChange={(ev, opt) => opt && setField("country", opt.value)}
                    textFieldProps={{ variant: "standard", margin: 'normal' }} />

                <LanguageSelect
                    value={profile.language}
                    label={'Language'}
                    onChange={(ev, opt) => opt && setField("language", opt.value)}
                    textFieldProps={{ variant: "standard", margin: 'normal' }} />

                <FormControlLabel
                    sx={{ mt: 2 }}
                    control={
                        <Checkbox
                            checked={profile.newsletter}
                            onChange={e => setField("newsletter", e.target.checked)} />
                    }
                    label="Allow sending informative newsletters" />

                <StatusAlert
                    sx={{ mt: 3 }}
                    visible={Boolean(error)}
                    variant="error"
                    message={error && error.message}>
                </StatusAlert>
            </Box>
        </FullScreenPage>
    );
}
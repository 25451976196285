import countries from '../utils/countries.json';
import languages from '../utils/languages.json';

const tokenCookieName = "jwttoken";

let languageOptionsById = null;
let languageOptions = null;

let countryOptionsById = null;
let countryOptions = null;

function fillLanguageOptions() {
    languageOptionsById = {};
    languageOptions = [];
    for (const l of Object.keys(languages)) {
        const lang = { value: l, label: languages[l] };
        languageOptionsById[l] = lang;
        languageOptions.push(lang);
    }
}

function fillCountryOptions () {
    countryOptionsById = {};
    countryOptions = [];
    for (const c of Object.keys(countries)) {
        const country = { value: c, label: countries[c] };
        countryOptionsById[c] = country;
        countryOptions.push(country);
    }
}

export function getLanguagesOptions() {
    if (!languageOptions) fillLanguageOptions();
    return languageOptions;
}

export function getLanguagesOptionsById() {
    if (!languageOptionsById) fillLanguageOptions();
    return languageOptionsById;
}

export function getCountryOptions() {
    if (!countryOptions) fillCountryOptions();
    return countryOptions;
}

export function getCountryOptionsById() {
    if (!countryOptionsById) fillCountryOptions();
    return countryOptionsById;
}

export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};

export function isValidJwtToken(tokenData) {
    if (!tokenData || !tokenData.exp) return false;
    const expriation = (tokenData.exp - 120) * 1000; // exp-120seg
    return expriation > new Date();
}

export function setJwtTokenCookie(token) {
    document.cookie = `${tokenCookieName}=${token}`;
}

export function getJwtTokenCookie() {
    const cookies = Object.fromEntries(
        document.cookie.split(/\s*;\s*/).map(e => e.split(/\s*=\s*/))
    );
    return cookies[tokenCookieName];
}

export function deleteJwtTokenCookie() {
    document.cookie = `${tokenCookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export function isEmailValid(email) {
    return /^[\w-]+(?:\.[\w-]+)*@[\w-]+(?:\.[\w-]+)*\.\w+$/.test(email);
}

export function urlDecode(value) {
    return decodeURIComponent(value.replace(/\+/g, " "));
}

export function urlEncode(value) {
    return encodeURIComponent(value);
}

export function getQueryParams(location) {
    if (location.search.length === 0) return {};
    return Object.fromEntries(
        location.search.substring(1).split("&").map(i => {
            const [key, encodedValue] = i.split("=");
            const value = urlDecode(encodedValue);
            return [key, value];
        })
    );
}